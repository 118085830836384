<template>
  <b-modal
    id="change-situation-modal"
    hide-footer
    hide-header
    centered
    size="md"
    @hidden="onHidden"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Alterar situação</p>
      <Close class="close" style="cursor: pointer" @click="closeModal" />
    </div>

    <div class="body d-flex flex-column">
        <b-row>
            <multiselect
                placeholder="Situação"
                v-model="situation"
                class="with-border full"
                :options="situationOptions"
                :showLabels="false"
                :internalSearch="false"
                :searchable="false"
                @select="handleSituation"
            >
                <template slot="caret">
                <div class="chevron">
                    <ChevronDown />
                </div>
                </template>
                <template slot="option" slot-scope="{ option }">
                <span class="option" >
                    <div :class="`situation ${option.value.toLowerCase()}`"/>
                    {{option.label}}
                </span>
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                <span class="option" >
                    <div :class="`situation ${option.value.toLowerCase()}`"/>
                    {{option.label }}
                </span>
                </template>

                <template slot="noOptions">
                Nenhuma opção
                </template>

                <template slot="noResult">
                Nenhum resultado
                </template>
            </multiselect>    
        </b-row>

        <b-row 
            v-if="situation"
            class="mt-3"
        >
            <b-col 
                cols="12"
                class="text"
            >
                Deseja realmente alterar a situação do(s) agendamento(s) selecionado(s)?
            </b-col>

            <b-col 
                cols="12"
                class="d-flex justify-content-between mt-3"
            >  
                <b-button
                    variant="outline-danger"
                    @click="closeModal"
                >
                    Cancelar
                </b-button>

                <b-button
                    variant="primary"
                    size="lg"
                    @click="changeSituation"
                >
                    Confirmar
                </b-button>
            </b-col>
        </b-row>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Close,
    ChevronDown
  },
  props: {
    appointments: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      situation: null,
      situationOptions: [
        {value:'SCHEDULED', label: 'Sem situação'},
        {value:'CONFIRMED', label: 'Confirmado'},
        {value:'ATTEND', label: 'Compareceu'},
        {value:'WAITING', label: 'Aguardando atendimento'},
        {value:'MISSED', label: 'Faltou'},
        {value:'CANCELLED', label: 'Cancelado'},
        {value:'HAPPENING', label: 'Em atendimento médico'},
        {value:'EXAMINATING', label: 'Em exames'},
        {value:'DILATING', label: 'Dilatando'},
        {value:'FINISHED', label: 'Finalizado'},
        {value:'PRE_FINISHED', label: 'Pré-consulta finaliza'},
        {value:'IN_PRE_CONSULTATION', label: 'Em pré-consulta'}
      ]
    }
  },
  methods: {
    async changeSituation() {
      try {
        const res = await this.api.changeManyAppointmentSituation({
            appointmentIds: this.appointments,
            situation: this.situation
        })
        if(res.data){
            this.$emit('change-situation')
            this.$toast.success('Situação atualizado com sucesso')
            this.closeModal()
        }
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    onHidden() {
      this.situation = null
    },
    closeModal() {
      this.$bvModal.hide('change-situation-modal')
    },
    handleSituation(value) {
      this.situation = value
    },
  }
}
</script>
<style lang="scss">
#change-situation-modal {
  .modal-content {
    border-radius: 8px !important;
  }

  .modal-dialog {
    width: 765px !important;
  }

  .modal-body {
    padding: 0 !important;

    .header {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);

      .title {
        font-family: 'Nunito Sans';
        font-weight: 600;
        font-size: 18px;
        margin: 0;
        color: var(--type-active);
      }

      svg {
        width: 24px;
        height: 24px;
        fill: var(--dark-blue);
      }
    }

    .body {
      padding: 24px;
    }
  }

  .text{
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);
  }
}
</style>
